import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './VendorStatus.css';
import { useVendorContext } from './VendorContext';
import { useNavigate } from 'react-router-dom';

const VendorPage = () => {
  const navigate = useNavigate();
  const { vendorNo, vendorStatus } = useVendorContext();
  const [vendorDetails, setVendorDetails] = useState([]);
  const [ isBpCreated , setisBpCreated ] = useState(false);
  const destination = "myhome";
  const [loading, setLoading]=useState(false);
  const formData={
    userId:vendorNo,
    password:""
  };
  console.log(formData);

  console.log("vendorNo, vendorStatus from VendorContext is: ", vendorNo, vendorStatus);
  useEffect(() => {
    const username = process.env.REACT_APP_USERNAME;
    const password = process.env.REACT_APP_PASSWORD;
    const credentials = `${username}:${password}`;
    const base64Credentials = btoa(credentials);
    setLoading(true);
    const fetchData = async () => {
      try {
        const apiUrl=`${process.env.REACT_APP_API_URL_USER}${formData.userId}`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': 'Basic ' + base64Credentials,
            // 'Access-Control-Allow-Origin': 'http://localhost:3000',
              "Content-Type": 'application/x-www-form-urlencoded',
              // 'Access-Control-Allow-Credentials': true,
              // 'Access-Control-Allow-Methods': '*',
              // 'Access-Control-Allow-Headers': '*',
          },
        });

        if (!response.ok) {
          setLoading(false);
          throw new Error('Network response was not ok');
        }

        const apiData = await response.json();
        // const apiData = await axios.post('http://localhost:3000/api/data', { formData });
        setLoading(false);
        setVendorDetails([apiData.VENDOR_NO, apiData.VEND_STATUS, apiData.VEND_DATE, apiData.LIFNR]);
        if(apiData.LIFNR) {
          setisBpCreated(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        return alert('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleViewData = () => {
    navigate(`/Registration3/${destination}`);
  }

  const formatDateToDMY = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  return (
    <div>
      {loading && (
        <div className='overlay'>
          <div className='spinner-container'>
            <div className="loading-spinner"></div>
            <span className='loading-message'>Loading...</span>
          </div>
        </div>
      )}
      <div className='vendor-status-container'>
        <div className='vendor-details-table'>
          <table className='table-alignment'>
            <thead>
              <tr>
                <th colSpan="4"><h5 className='text-center reference-details-heading'>Vendor Reference Details</h5></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" className='table-content-label'>Reference Vendor Number</td>
                <td colSpan="2" className='table-content-values'>{vendorDetails[0]}</td>
              </tr>
              <tr>
                <td colSpan="2" className='table-content-label'>Vendor Status</td>
                <td colSpan="2" className='table-content-values'>{vendorDetails[1]}</td>
              </tr>
              <tr>
                <td colSpan="2" className='table-content-label'>Requested Date</td>
                <td colSpan="2" className='table-content-values'>{formatDateToDMY(vendorDetails[2])}</td>
              </tr>
              { isBpCreated ? (
                <tr>
                <td colSpan="2" className='table-content-label'>Vendor Number (LIFNR) </td>
                <td colSpan="2" className='table-content-values'>{vendorDetails[3]}</td>
              </tr>
              ):(
                <tr></tr>
              )}

            </tbody>
          </table>
        </div>
        <div className='mt-3'>
          <button className='btn btn-primary custom-view-details-button' onClick={handleViewData}>View Details</button>
        </div>
      </div>
    </div>
  );
};

export default VendorPage;