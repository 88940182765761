import React, { useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useCallback } from 'react';

const CustomDropzone = ({ onDrop, field, errors, selectedFileName, setSelectedFile, isSkipChecked, setSkipChecked, fileUrl }) => {
  const handleSkipChange = (e) => {
    const isChecked = e.target.checked;
    setSkipChecked(isChecked);

    if (isChecked) {
      onDrop([], field);
    } else {
      errors[field] && delete errors[field];
    }
  };

  const handleAttachClick = (e) => {
    if (isSkipChecked) {
      e.preventDefault();
    }
  };

  return (
    <div style={{ textAlign: 'left', display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '0.25rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ flex: '60%' }}>
          <input
            type="text"
            value={selectedFileName || ''}
            className='document-input-field'
            readOnly
            onClick={() => setSelectedFile(field)}
            style={{ width: '100%' }}
            disabled={isSkipChecked}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: '20%', marginRight: '0' }}>
          <label
            className={`attach-button-container btn btn-primary${isSkipChecked ? ' disabled' : ''}`}
            style={{ marginTop: '0', display: 'flex' }}
            onClick={handleAttachClick}
          >
            <AttachFileIcon style={{ display: 'flex', transform: 'rotate(215deg)', fontSize: '1rem', margin: '0' }} />
            Attach
            <input
              type="file"
              accept='.pdf, .jpg, .jpeg, .png'
              onChange={(e) => onDrop(e.target.files, field)}
              style={{ display: 'none' }}
              disabled={isSkipChecked}
            />
          </label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: '20%' }}>
          <label className="skip-checkbox">
            <input
              type="checkbox"
              onChange={handleSkipChange}
              checked={isSkipChecked}
            />
            <span style={{ position: 'relative', bottom: '0.2rem' }}>Skip</span>
          </label>
        </div>
      </div>
      <div style={{ width: '100%' }} className="error-message">
        {errors[field] && !isSkipChecked && <span className="text-danger">{errors[field]}</span>}
      </div>
      {fileUrl && (
        <div style={{ marginTop: '1rem', width: '100%' }}>
          <h4>File Preview:</h4>
          {selectedFileName && selectedFileName.endsWith('.pdf') ? (
            <embed src={fileUrl} type="application/pdf" width="100%" height="500px" />
          ) : (
            <img src={fileUrl} alt="Preview" style={{ width: '100%', maxHeight: '500px' }} />
          )}
        </div>
      )}
    </div>
  );
};


const FileUploadForm = () => {
    const [errors, setErrors] = useState({});
    const [selectedFileNames, setSelectedFileNames] = useState({});
    const [fileUrl, setFileUrl] = useState({});
    const [skipCheckedState, setSkipCheckedState] = useState({});
    
  
    const onDrop = useCallback((acceptedFiles, field) => {
      const file = acceptedFiles[0];
  
      if (!file) {
        setErrors({ ...errors, [field]: 'Please select a file.' });
        setSelectedFileNames({ ...selectedFileNames, [field]: null });
        return;
      }
  
      if (file.size > 5 * 1024 * 1024) {
        setErrors({ ...errors, [field]: 'File size exceeds 5MB limit.' });
        setSelectedFileNames({ ...selectedFileNames, [field]: null });
        return;
      }
  
      const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
      if (!acceptedTypes.includes(file.type)) {
        setErrors({ ...errors, [field]: 'Unsupported file type.' });
        setSelectedFileNames({ ...selectedFileNames, [field]: null });
        return;
      }
      setErrors({ ...errors, [field]: null });
  
      setSelectedFileNames({ ...selectedFileNames, [field]: file.name });
  
      // Set the file URL for preview
      setFileUrl({ ...fileUrl, [field]: URL.createObjectURL(file) });
      console.log("FileURL is: ", fileUrl);
      const formData = new FormData();
      formData.append('file', file);
    }, [errors, selectedFileNames, fileUrl]);
  
    const documentTypes = [
      { field: 'panDocument', label: 'PAN Document' },
      { field: 'msmeDocument', label: 'MSME Document' },
      { field: 'gstDocument', label: 'GST Document' },
      // { field: 'pfCertificate', label: 'PF Certificate' },
      { field: 'cancelledCheque', label: 'Cancelled Cheque' },
      { field: 'otherDocument', label: 'Other Document' },
    ];
  
    const setSelectedFile = (field) => {
      const inputElement = document.getElementById(field);
      if (inputElement) {
        inputElement.click();
      }
    };
  
    return (
      <div className="container mt-0">
        {documentTypes.map((docType) => (
          <div className="row mt-2" key={docType.field}>
            <div className="col-5 col-md-2 document-label">
              <label htmlFor={docType.field}>{docType.label}</label>
            </div>
            <div className="col-5 col-md-5 d-flex flex-column">
              <CustomDropzone
                onDrop={onDrop}
                field={docType.field}
                errors={errors}
                selectedFileName={selectedFileNames[docType.field]}
                setSelectedFile={() => setSelectedFile(docType.field)}
                isSkipChecked={skipCheckedState[docType.field] || false}
                setSkipChecked={(isChecked) => setSkipCheckedState({ ...skipCheckedState, [docType.field]: isChecked })}
                fileUrl={fileUrl[docType.field]}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default FileUploadForm;