import React, { useState } from 'react';
import './ForgetPassword.css';
import axios from 'axios';
import { useVendorContext } from './VendorContext';

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        userId: '',
    });

    const [status, setStatus]=useState({
        message: '',
        mail: '',
        error:'',
    })

    const { setVendorNumber } = useVendorContext();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        const username = 'SRIAABAP';
        const password = 'Patil@2234';
        const basicAuth = 'Basic ' + btoa(username + ':' + password);
        const apiUrl=`http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200&AUTO_USER=${formData.userId}`;
        const postApiUrl=`http://10.10.121.115:8001/sap/bc/rest/zvendor/Vendor?sap-client=200`;
        try {
            setVendorNumber(formData.userId);
    
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Headers': '*',
                },
            });
            console.log(response.data.VENDOR_P_EMAIL);
            console.log("123");
            if (response.status===200 && response.data) {
                const email=await response.data.VENDOR_P_EMAIL;
                const [userName, domainMail]=email.split("@");
                const userNameLength=userName.length;
                
                const response1 = await axios.post(postApiUrl, 
                    { "AUTO_USER": formData.userId },{
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': basicAuth,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': true,
                        'Access-Control-Allow-Methods': '*',
                        'Access-Control-Allow-Headers': '*',
                    },
                });
                console.log(response1);
                if(response1.status===200) {
                    setStatus((prevMessages) => ({
                        ...prevMessages,
                        message: 'Please check your Registered E-mail.', 
                        mail: 'We have sent your Password to: '+ email.substring(0,1)+"***"+email.substring(userNameLength-3,userNameLength)+"@"+domainMail ,
                        error: '',
                      }));
                }
                else {
                    setStatus((prevMessages) => ({
                        ...prevMessages,
                        message: '',
                        mail: '',
                        error: 'Error in requesting Password.', 
                      }));
                }

            } else {
                // Handle error, e.g., display an error message
                setStatus((prevMessages) => ({
                    ...prevMessages,
                    message: '',
                    mail: '',
                    error: "User ID doesn't exist, Please check User ID",
                  }));
            }
        } catch (error) {
            console.error("Error");
            // Handle network errors
            setStatus((prevMessages) => ({
                ...prevMessages,
                message: '',
                mail: '',
                error: "Network Error",
              }));
        }

    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card my-custom-card">
                        <div className="card-header">
                            <h2>Forgot Password</h2>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleForgotPassword}>
                                <div className="form-group">
                                    <label htmlFor="userId" className="label-name">User ID</label>
                                    <input
                                        type="text"
                                        className="form-control input-field"
                                        id="userId"
                                        name="userId"
                                        placeholder="Enter User ID"
                                        value={formData.userId}
                                        onChange={handleInputChange}
                                        maxLength="50"
                                        required
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary btn-block mt-3">
                                    Request Password
                                </button>

                                <div className='response-container mt-2'>
                                    {status.message && <p className='response success-response'>{ status.message }</p>}
                                    {status.mail && <p className='response success-response'>{ status.mail }</p>}
                                    {status.error && <p className='response failure-response'>{ status.error }</p>}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
